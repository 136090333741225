<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Phiếu hủy hàng</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Chi nhánh</div>
        <v-autocomplete
          v-model="selectedBranches"
          class="tp-filter-autocomplete"
          :items="branches"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn chi nhánh"
        ></v-autocomplete>
      </div>
      <div class="mt-5 pb-2">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhân viên hủy</div>
        <v-autocomplete
          v-model="selectedEmployees"
          class="tp-filter-autocomplete"
          :items="employees"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn nhân viên"
        ></v-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    branchesFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    employeesFilter: {
      type: Array
    }
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    selectedBranches: {
      get() {
        return this.branchesFilter;
      },
      set(val) {
        this.$emit("updateBranchesFilter", val);
      }
    },
    selectedEmployees: {
      get() {
        return this.employeesFilter;
      },
      set(val) {
        this.$emit("updateEmployeesFilter", val);
      }
    }
  },
  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
    this.$store.dispatch("EMPLOYEE/getAllEmployees", "ENGINEERING");
  },
  methods: {
    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
