<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="
      goodsDestructionStatusRequest.value === 'loading-getGoodsDestructionList'
    "
    loading-text="Đang tải dữ liệu"
    :items="goodsDestructionList"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.product_name`]="{ item }">
      <div class="my-1">
        <div class="">
          {{ item.product_name }}
        </div>
        <div
          class="sub--text text-subtitle-2 font-weight-light grey--text text--darken-2"
          v-html="item.option_name"
        >
          {{ item.option_name }}
        </div>
      </div>
    </template>

    <template v-slot:[`item.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.serial_number)"
          >
            {{ item.serial_number }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.created_price`]="{ item }">
      {{ item.created_price | formatCurrency }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã phiếu hủy",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial_number"
        },
        {
          text: "Thời gian",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Nhân viên",
          align: "start",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Giá nhập",
          align: "start",
          sortable: false,
          value: "created_price"
        }
      ]
    };
  },
  computed: {
    goodsDestructionList() {
      return this.$store.getters["GOODS_DESTRUCTION/goodsDestructionList"];
    },
    goodsDestructionStatusRequest() {
      return this.$store.getters["GOODS_DESTRUCTION/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfSaleReceipt(val) {
      const arr = this.saleReceipts.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$store.dispatch(
        "GOODS_DESTRUCTION/getGoodsDestructionById",
        item.id
      );

      this.$modal.show({
        name: "modal-goods-destruction"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .sub--text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
</style>
